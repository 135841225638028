import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import TwoColumnTable from "./components/two.column.table";
import HeaderCommission from "./components/header.commission";

// Font.register({
//   family: "Calibri-Bold",
//   src: require("./fonts/Calibri-Bold.TTF"),
// });

// Font.register({
//   family: "Calibri",
//   src: require("./fonts/Calibri Light.ttf"),
// });

// Font.register({
//   family: "Calibri-Regular",
//   src: require("./fonts/Calibri-Regular.ttf"),
// });

// Font.register({
//   family: "Cambria",
//   src: require("./fonts/Cambria.ttf"),
// });

// Font.register({
//   family: "Times",
//   src: require("./fonts/times.ttf"),
// });

// Font.register({
//   family: "Times-Bold",
//   src: require("./fonts/times-bold.ttf"),
// });

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  mainView: {
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40,
  },
  container: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },

  header: {
    // fontSize: 15,
    marginBottom: 1,
    textAlign: "left",
    // fontFamily: "Calibri-Bold",
  },
  header_small: {
    fontSize: 8,
    marginBottom: 1,
    textAlign: "left",
    color: "black",
    // fontFamily: "Calibri-Regular",
  },
  topText: {
    textTransform: "uppercase",
    fontSize: 12.95,
    // fontFamily: "Calibri-Regular",
    paddingBottom: 3,
  },
  topSecondText: {
    marginTop: 5,
    textTransform: "uppercase",
    fontSize: 12.95,
    // fontFamily: "Calibri",
    paddingBottom: 3,
  },

  secText: {
    marginTop: 5,
    fontSize: 12,
    // fontFamily: "Calibri",
  },
  secBoldText: {
    marginTop: 5,
    fontSize: 14,
    // fontFamily: "Calibri-Bold",
  },

  regardHeader: {
    fontSize: 15,
    color: "black",
    // fontFamily: "Calibri-Regular",
  },
  regardText: {
    fontSize: 15,
    color: "black",
    // fontFamily: "Calibri-Bold",
  },

  twoColumnBorder: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
  },
  eHakColumnText: {
    alignItems: "flex-center",
    marginLeft: 8,
    fontSize: 14,
    color: "black",
    // fontFamily: "Calibri-Regular",
  },
});

const EHak = ({ data }) => {
  const data_shown_name = data?.ap_detail?.shown_name;

  const renderLogo = () => {
    switch (data_shown_name) {
      case "DCF":
        return require("../../assets/images/FDS-logo.png");
      case "DCFP":
        return require("../../assets/images/FP-logo.png");
      case "DCK":
        return require("../../assets/images/KR-logo.png");
      case "DCGS":
        return require("../../assets/images/GS-logo.JPG");
      case "DCP":
        return require("../../assets/images/DCP-Logo.jpg");
      case "DCHO":
        return require("../../assets/images/DCHO-logo.png");
      case "DCH AUTOMOBILE":
        return require("../../assets/images/DCH-logo.png");
      default:
        return require("../../assets/images/FDS-logo.png");
    }
  };

  const renderAddressAP = () => {
    switch (data_shown_name) {
      case "DCF":
        return (
          <View style={{ marginTop: 5 }}>
            {data.ap_id == 1 && (
              <div>
                <Text style={styles.topText}>NO. 2 & 4, </Text>
                <Text style={styles.topText}>
                  JALAN PERDA UTAMA 7 BANDAR PERDA,
                </Text>
                <Text style={styles.topText}>
                  14000 BUKIT MERTAJAM PULAU PINANG. (HQ)
                </Text>
                <Text style={[styles.topText, { paddingBottom: 10 }]}>
                  CONTACT NO :{" "}
                  <Text style={{ textDecoration: "underline" }}>
                    04-5308999
                  </Text>
                </Text>
              </div>
            )}
            <Text style={styles.topText}>NO. 1, JALAN ASTAKA 4/KU2, </Text>
            <Text style={styles.topText}>BANDAR BUKIT RAJA,</Text>
            <Text style={styles.topText}>
              41050 KLANG, SELANGOR DARUL EHSAN.
            </Text>
            <Text style={styles.topText}>( SHOWROOM )</Text>
          </View>
        );
      case "DCFP":
        return (
          <View style={{ marginTop: 5 }}>
            <Text style={styles.topText}>No 3, Jalan Astana 1D,</Text>
            <Text style={styles.topText}>Bandar Bukit Raja,</Text>
            <Text style={styles.topText}>41050 Klang, Selangor.</Text>
          </View>
        );
      case "DCK":
        return (
          <View style={{ marginTop: 5 }}>
            <Text style={styles.topText}>22A, Jalan Astana 1B,</Text>
            <Text style={styles.topText}>Bandar Bukit Raja,</Text>
            <Text style={styles.topText}>41050 Klang Selangor.</Text>
          </View>
        );
      case "DCGS":
        return (
          <View style={{ marginTop: 5 }}>
            <Text style={styles.topText}>No.3, Jalan Kenanga 1/1,</Text>
            <Text style={styles.topText}>Salak Perdana,</Text>
            <Text style={styles.topText}>43900 Sepang ,Selangor.</Text>
          </View>
        );
      case "DCP":
        return (
          <View style={{ marginTop: 5 }}>
            <Text style={styles.topText}>No.6, Jalan Astana 1B,</Text>
            <Text style={styles.topText}>Bandar Bukit Raja,</Text>
            <Text style={styles.topText}>41050 Klang ,Selangor.</Text>
          </View>
        );
      case "DCHO":
        return (
          <View style={{ marginTop: 5 }}>
            <Text style={styles.topText}>27, Jalan Pemberita U1/49,</Text>
            <Text style={styles.topText}>Hicom-glenmarie Industrial Park,</Text>
            <Text style={styles.topText}>40150 Shah Alam, Selangor.</Text>
          </View>
        );
      case "DCH AUTOMOBILE":
        return (
          <View style={{ marginTop: 5 }}>
            <Text style={styles.topText}>No.7, Jalan Astana 1F/KU2,</Text>
            <Text style={styles.topText}>Bandar Bukit Raja,</Text>
            <Text style={styles.topText}>41050 Klang ,Selangor.</Text>
          </View>
        );
      default:
        return (
          <View style={{ marginTop: 15 }}>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              NO. 1, JALAN ASTAKA 4/KU2,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              BANDAR BUKIT RAJA,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              41050 KLANG,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              SELANGOR DARUL EHSAN.
            </Text>
          </View>
        );
    }
  };

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.mainView}>
          <View style={styles.container}>
            <View
              style={{
                flex: 3,
                marginRight: 20,
                marginTop: -50,
              }}
            >
              <Image style={{ width: "100%" }} src={renderLogo()} />
            </View>
            <View style={{ flex: 9 }}>
              <Text
                style={[
                  styles.header,
                  { fontSize: data?.ap_id === 3 ? 15 : 15 },
                ]}
                fixed
              >
                {data?.ap_detail?.name}{" "}
                <Text style={styles.header_small}>
                  ({data?.ap_detail?.register_no})
                </Text>
              </Text>
              {renderAddressAP()}
              <Text style={styles.topText}>
                CONTACT NO :{" "}
                <Text style={{ textDecoration: "underline" }}>
                  {data?.ap_detail?.contact}
                </Text>
              </Text>
            </View>
          </View>
          <View
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <Text style={styles.secText}>
              To :{" "}
              <Text style={styles.secBoldText}>
                {" "}
                {data?.banker_name}
                {data?.bank_loan_name ? `(${data.bank_loan_name})` : ""}
              </Text>
            </Text>
            <Text style={styles.secText}>
              From :{" "}
              <Text style={styles.secBoldText}> {data?.ap_detail?.name}</Text>
            </Text>
            <Text style={styles.secText}>
              Subject :{" "}
              <Text style={styles.secBoldText}> E-HAKMILIK REQUEST</Text>
            </Text>
          </View>

          <div>
            <HeaderCommission title1={null} type="nullBlueColumns" />

            <TwoColumnTable
              column1="Bank"
              column2={data?.bank_loan_name}
              type="eHakList"
            />
            <TwoColumnTable
              column1="Name of Hirer"
              column2={data?.personal_name}
              type="eHakList"
            />
            <TwoColumnTable
              column1="I/C No."
              column2={data?.identity_no}
              type="eHakList"
            />
            <TwoColumnTable
              column1="Vehicle Make / Model"
              column2={`${data?.vehicle_make ? data.vehicle_make : ""} ${
                data?.vehicle_model ? `/ ${data.vehicle_model}` : ""
              }`}
              type="eHakList"
            />
            <TwoColumnTable
              column1="Engine Number"
              column2={data?.vehicle_engine_no}
              type="eHakList"
            />
            <View style={styles.twoColumnBorder}>
              <View
                style={{ flex: 5, borderRight: "1px solid black", padding: 8 }}
              >
                <Text style={styles.eHakColumnText}>Chassis Number</Text>
              </View>
              <View style={{ flex: 7, padding: 8 }}>
                <Text style={styles.eHakColumnText}>
                  {data?.vehicle_chasis_no}
                </Text>
              </View>
            </View>
          </div>

          <View
            style={{
              marginTop: 60,
            }}
          >
            <Text style={styles.regardHeader}>Regards,</Text>
            <Text style={styles.regardText}>{data?.ap_detail?.name}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default EHak;

import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Table,
} from "@react-pdf/renderer";

import Signture from "../../../assets/images/signture.png";

import InterBold from "../fonts/Inter.ttf";

// Font.register({
//   family: "Oswald",
//   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
// });

Font.register({
  family: "Inter-Bold",
  src: InterBold,
});

const styles = StyleSheet.create({
  container: {
    // fontFamily: "Inter",
    height: "auto",
  },
  borderBottom: {
    borderBottom: "1px solid black",
  },
  borderRight: {
    borderRight: "1px solid black",
  },
  table: {
    display: "flex",
    gap: "1px",
    flexDirection: "row",
  },
});

const VehicleAgreement = ({ data }) => {
  const data_shown_name = data?.ap_detail?.shown_name;

  const renderChop = () => {
    switch (data_shown_name) {
      case "DCF":
        return Signture;
      case "DCFP":
        return require("../../../assets/images/FP-chop.png");
      case "DCK":
        return require("../../../assets/images/KR-chop.png");
      case "DCGS":
        return require("../../../assets/images/GS-chop.png");
      case "DCP":
        return require("../../../assets/images/DCP-chop.png");
      case "DCHO":
        return require("../../../assets/images/DCHO-chop.png");
      case "DCH AUTOMOBILE":
        return require("../../../assets/images/DCH-chop.png");
      default:
        return Signture;
    }
  };

  const renderBackgroundColor = () => {
    switch (data_shown_name) {
      case "DCF":
        return "#D22B2B";
      case "DCFP":
        return "#D22B2B";
      case "DCK":
        return "#D22B2B";
      case "DCGS":
        return "#2CEEC7";
      case "DCP":
        return "#c3a372";
      case "DCHO":
        return "#00b8ad";
      default:
        return "#D22B2B";
    }
  };

  return (
    <View style={[styles.container]}>
      <View style={[styles.table]}>
        <Text
          style={[
            {
              fontSize: "10px",
              paddingLeft: "2px",
              paddingTop: "2px",
              fontFamily: "Inter-Bold",
            },
          ]}
        >
          * AGREEMENT:
        </Text>
      </View>
      <View>
        <Text
          style={[
            {
              fontSize: "8px",
              padding: "3px",
              // fontFamily: "Inter",
              lineHeight: "1.5px",
            },
          ]}
        >
          I/WE* hereby agree to purchase the abovementioned vehicle and extra at
          the price shown above on the terms and conditions printed herein , in
          respect of which:-
        </Text>
        <Text
          style={[
            {
              fontSize: "8px",
              padding: "3px",
              // fontFamily: "Inter",
              lineHeight: "1.5px",
            },
          ]}
        >
          **RM30,000 DEPOSIT BEFORE CAR BIDDING / PURCHASE
        </Text>
        <Text
          style={[
            {
              fontSize: "8px",
              padding: "3px",
              // fontFamily: "Inter",
              lineHeight: "1.5px",
            },
          ]}
        >
          ***30% OF TOTAL PURCHASE PRICE - NON REFUNDABLE IF ANY CANCELLATION
        </Text>
      </View>
      <View
        style={{
          backgroundColor: renderBackgroundColor(), // fontFamily: "Inter-Bold"
        }}
      >
        <Text
          style={[
            {
              fontSize: "8px",
              padding: "3px",
            },
          ]}
        >
          ****TOTAL DEPOSIT CAN ONLY BE REFUND IN THE RESULT OF: VEHICLE COLOR,
          CHASSIS, AND SPEC ARE DIFFERENT BEFORE YOU MAKE DEPOSIT.
        </Text>
        <Text
          style={[
            {
              fontSize: "8px",
              padding: "3px",
            },
          ]}
        >
          ***ACTUAL DUTY IS SUBJECT TO FINAL CONFIRMATION FROM CUSTOMS
          MALAYSIA***
        </Text>
        <Text
          style={[
            {
              fontSize: "8px",
              padding: "3px",
            },
          ]}
        >
          *PLEASE NOTE THAT THE FIRST YEAR CAR INSURANCE IS COMPULSORY ISSUE BY
          OUR COMPANY*
        </Text>
      </View>
      <View style={[styles.table, { paddingTop: "5px" }]}>
        <Text
          style={[
            {
              flex: 6,
              fontSize: "8px",
              paddingRight: "2px",
              fontFamily: "Inter-Bold",
              textAlign: "right",
            },
          ]}
        >
          :
        </Text>
        <Text
          style={[
            styles.borderBottom,
            {
              flex: 6,
              fontSize: "8px",
              paddingRight: "2px",
              fontFamily: "Inter-Bold",
              textAlign: "center",
            },
          ]}
        ></Text>
      </View>
      <View style={[styles.table, styles.borderBottom]}>
        <Text
          style={[
            {
              flex: 6,
              fontSize: "8px",
              paddingRight: "2px",
              fontFamily: "Inter-Bold",
              textAlign: "right",
            },
          ]}
        ></Text>
        <Text
          style={[
            {
              flex: 6,
              fontSize: "8px",
              paddingRight: "2px",
              paddingTop: "5px",
              paddingBottom: "5px",
              fontFamily: "Inter-Bold",
              textAlign: "center",
            },
          ]}
        >
          Date :{" "}
          {data?.sales_quotation?.date
            ? new Date(data.sales_quotation.date).toLocaleDateString()
            : ""}{" "}
          Buyer/Hirer*
        </Text>
      </View>
      <View
        style={[
          styles.table,
          styles.borderBottom,
          { padding: "5px", paddingBottom: "29px" },
        ]}
      >
        <Text
          style={[
            {
              flex: 6,
              fontSize: "10px",
              paddingRight: "2px",
              fontFamily: "Inter-Bold",
            },
          ]}
        >
          OFFICE USE:
        </Text>
        <View style={{ flex: 6, position: "relative" }}>
          <Text
            style={[
              {
                position: "relative",
                zIndex: 4,
                fontSize: "10px",
                paddingRight: "2px",
                // fontFamily: "Inter-Bold",
                textAlign: "center",
              },
            ]}
          >
            AGREED ON BEHALF OF
          </Text>
          <Text
            style={[
              {
                position: "relative",
                zIndex: 4,
                fontSize: "10px",
                paddingRight: "2px",
                // fontFamily: "Inter-Bold",
                textAlign: "center",
              },
            ]}
          >
            {data?.ap_detail?.name}
          </Text>
          <Image
            style={{
              position: "absolute",
              width:
                data_shown_name == "DCP"
                  ? "50px"
                  : data_shown_name == "DCHO"
                    ? "150px"
                    : "90px",
              top: data_shown_name == "DCP" ? 0 : 10,
              left: data_shown_name == "DCHO" ? -150 : -100,
              zIndex: 0,
              marginLeft: "auto",
              marginRight: "auto",
            }}
            src={renderChop()}
          />
        </View>
      </View>
      <View style={[styles.table, { paddingBottom: "10px" }]}>
        <View style={{ flex: 6 }}>
          <Text
            style={[
              {
                fontSize: "8px",
                paddingRight: "2px",
                paddingTop: "2px",
                // fontFamily: "Inter",
                textAlign: "center",
                paddingBottom: "5px",
              },
            ]}
          >
            SALES TEAM:
          </Text>
          <Text
            style={[
              {
                fontSize: "8px",
                // fontFamily: "Inter",
                textAlign: "center",
              },
            ]}
          >
            {data?.sales_agent_name} @ {data?.sales_agent_contact}
          </Text>
        </View>
        <View style={{ flex: 6 }}>
          <Text
            style={[
              {
                fontSize: "8px",
                paddingRight: "2px",
                paddingTop: "2px",
                // fontFamily: "Inter",
                textAlign: "center",
                paddingBottom: "5px",
              },
            ]}
          >
            SALES TEAM:
          </Text>
          <Text
            style={[
              {
                fontSize: "8px",
                // fontFamily: "Inter",
                textAlign: "center",
              },
            ]}
          >
            BUKIT RAJA, KLANG
          </Text>
        </View>
      </View>
    </View>
  );
};

export default VehicleAgreement;

import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import FadasonLogo from "./../../assets/images/fadason-logo.jpg";
import InterSemiBold from "./fonts/Inter-SemiBold.ttf";

// Font.register({
//   family: "Oswald",
//   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
// });

// Font.register({
//   family: "Calibri-Regular",
//   src: require("./fonts/Calibri-Regular.ttf"),
// });

// Font.register({
//   family: "Calibri-Bold",
//   src: require("./fonts/Calibri-Bold.TTF"),
// });

// Font.register({
//   family: "Cambria",
//   src: require("./fonts/Cambria.ttf"),
// });

// Font.register({
//   family: "Cambria-Bold",
//   src: require("./fonts/cambriab.ttf"),
// });

// Font.register({
//   family: "Times",
//   src: require("./fonts/times.ttf"),
// });

// Font.register({
//   family: "Times-Bold",
//   src: require("./fonts/times-bold.ttf"),
// });

Font.register({
  family: "Inter-SemiBold",
  src: InterSemiBold,
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  mainView: {
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40,
  },
  bottomView: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    // fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },

  header: {
    fontSize: 11.05,
    marginBottom: 1,
    textAlign: "left",
    // color: "red",
    // fontFamily: "Calibri-Bold",
  },
  header_small: {
    fontSize: 8,
    marginTop: 2,
    marginBottom: 1,
    textAlign: "left",
    color: "black",
    // fontFamily: "Calibri-Bold",
  },
  topText: {
    fontSize: 11.05,
    // fontFamily: "Calibri-Bold",
    paddingBottom: 1,
  },
  secText: {
    fontSize: 11,
    // fontFamily: "Cambria",
    // textAlign: "justify",
    marginBottom: 5,
  },
  paragraph: {
    fontSize: 11,
    // fontFamily: "Cambria",
    textAlign: "justify",
    lineHeight: 2,
    marginBottom: 5,
  },
  thirdText: {
    fontSize: 11,
    // fontFamily: "Cambria-Bold",
    textDecoration: "underline",
  },
  forthText: {
    fontSize: 10,
    // fontFamily: "Cambria-Bold",
  },
});

const FDSDisbusement = ({ data }) => {
  const data_shown_name = data?.ap_detail?.shown_name;

  const renderLogo = () => {
    switch (data_shown_name) {
      case "DCF":
        return require("../../assets/images/FDS-logo.png");
      case "DCFP":
        return require("../../assets/images/FP-logo.png");
      case "DCK":
        return require("../../assets/images/KR-logo.png");
      case "DCGS":
        return require("../../assets/images/GS-logo.JPG");
      case "DCP":
        return require("../../assets/images/DCP-Logo.jpg");
      case "DCHO":
        return require("../../assets/images/DCHO-logo.png");
      case "DCH AUTOMOBILE":
        return require("../../assets/images/DCH-logo.png");
      default:
        return require("../../assets/images/FDS-logo.png");
    }
  };

  const renderAddressAP = () => {
    switch (data_shown_name) {
      case "DCF":
        return (
          <View style={{ marginTop: 5 }}>
            {data.ap_id == 1 && (
              <div>
                <Text style={styles.topText}>NO. 2 & 4, </Text>
                <Text style={styles.topText}>
                  JALAN PERDA UTAMA 7 BANDAR PERDA,
                </Text>
                <Text style={styles.topText}>
                  14000 BUKIT MERTAJAM PULAU PINANG. (HQ)
                </Text>
                <Text style={[styles.topText, { paddingBottom: 10 }]}>
                  Tel : <Text style={{}}>04-5308999</Text>
                </Text>
              </div>
            )}
            <Text style={styles.topText}>NO. 1, JALAN ASTAKA 4/KU2, </Text>
            <Text style={styles.topText}>BANDAR BUKIT RAJA,</Text>
            <Text style={styles.topText}>
              41050 KLANG, SELANGOR DARUL EHSAN.
            </Text>
            <Text style={styles.topText}>( SHOWROOM )</Text>
          </View>
        );
      case "DCFP":
        return (
          <View style={{ marginTop: 5 }}>
            <Text style={styles.topText}>No 3, Jalan Astana 1D,</Text>
            <Text style={styles.topText}>Bandar Bukit Raja,</Text>
            <Text style={styles.topText}>41050 Klang, Selangor.</Text>
          </View>
        );
      case "DCK":
        return (
          <View style={{ marginTop: 5 }}>
            <Text style={styles.topText}>22A, Jalan Astana 1B,</Text>
            <Text style={styles.topText}>Bandar Bukit Raja,</Text>
            <Text style={styles.topText}>41050 Klang Selangor.</Text>
          </View>
        );
      case "DCGS":
        return (
          <View style={{ marginTop: 5 }}>
            <Text style={styles.topText}>No.3, Jalan Kenanga 1/1,</Text>
            <Text style={styles.topText}>Salak Perdana,</Text>
            <Text style={styles.topText}>43900 Sepang ,Selangor.</Text>
          </View>
        );
      case "DCP":
        return (
          <View style={{ marginTop: 5 }}>
            <Text style={styles.topText}>No.6, Jalan Astana 1B,</Text>
            <Text style={styles.topText}>Bandar Bukit Raja,</Text>
            <Text style={styles.topText}>41050 Klang ,Selangor.</Text>
          </View>
        );
      case "DCHO":
        return (
          <View style={{ marginTop: 5 }}>
            <Text style={styles.topText}>27, Jalan Pemberita U1/49,</Text>
            <Text style={styles.topText}>Hicom-glenmarie Industrial Park,</Text>
            <Text style={styles.topText}>40150 Shah Alam, Selangor.</Text>
          </View>
        );
      case "DCH AUTOMOBILE":
        return (
          <View style={{ marginTop: 5 }}>
            <Text style={styles.topText}>No.7, Jalan Astana 1F/KU2,</Text>
            <Text style={styles.topText}>Bandar Bukit Raja,</Text>
            <Text style={styles.topText}>41050 Klang ,Selangor.</Text>
          </View>
        );
      default:
        return (
          <View style={{ marginTop: 15 }}>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              NO. 1, JALAN ASTAKA 4/KU2,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              BANDAR BUKIT RAJA,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              41050 KLANG,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              SELANGOR DARUL EHSAN.
            </Text>
          </View>
        );
    }
  };

  const renderBank = () => {
    switch (data_shown_name) {
      case "DCK":
        return "STANDARD CHARTERED BANK";
      case "DCFP":
        return "STANDARD CHARTERED SAADIQ ISLAMIC BANKING";
      default:
        // return "UNITED OVERSEAS BANK (MALAYSIA) BHD";
        const bankName = data?.ap_detail?.bank_name;
        switch (bankName) {
          case "UOB":
            return "UNITED OVERSEAS BANK";
          case "SCB":
            return "STANDARD CHARTERED BANK";
          case "SCB Saadiq":
            return "STANDARD CHARTERED SAADIQ ISLAMIC BANKING";
          default:
            return bankName ?? "";
        }
    }
  };

  const renderSpecialAddress = () => {
    switch (data_shown_name) {
      // case "DCFP":
      //   return "48, Jalan PJU 5/8, Kota Damansara, 47810 Petaling Jaya, Selangor";
      case "DCK":
        return "No. 11, 15 & 17, Jalan Kepayang, 41050 Klang, Selangor";
      case "DCFP":
        return "No. 11, 15 & 17, Jalan Kepayang, 41050 Klang, Selangor";
      default:
        return data?.ap_detail?.address ?? "";
    }
  };

  const renderSpecialBankAcc = () => {
    switch (data_shown_name) {
      // case "DCFP":
      //   return "48, Jalan PJU 5/8, Kota Damansara, 47810 Petaling Jaya, Selangor";
      case "DCK":
        return "708409941649";
      case "DCFP":
        return "708409944869";
      default:
        return data?.ap_detail?.bank_acc ?? "";
    }
  };

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.mainView}>
          <View style={styles.container}>
            <View style={{ flex: 4, marginTop: -40, marginLeft: 40 }}>
              <Image style={{ width: "90%" }} src={renderLogo()} />
            </View>
            <View style={{ flex: 8 }}>
              <Text style={styles.header} fixed>
                {data?.ap_detail?.name}{" "}
                <Text style={styles.header_small}>
                  ({data?.ap_detail?.register_no})
                </Text>
              </Text>
              {renderAddressAP()}
              {/* <Text style={styles.topText}>{data?.ap_detail?.address}</Text> */}
              {/* <Text style={styles.topText}>
              14000 Bukit Mertajam, Pulau Pinang. ( HQ )
            </Text> */}
              <Text style={styles.topText}>
                Tel : {data?.ap_detail?.contact}
              </Text>
              {/* <Text style={styles.topText}>Fax :04-5302442</Text>
            <Text style={styles.topText}>
              No. 1, Jalan Astaka 4/KU2, Bandar Bukit Raja, 41050 Klang
            </Text>
            <Text style={styles.topText}>Selangor. ( SHOWROOM )</Text>
            <Text style={styles.topText}>Tel : 03-33599147</Text> */}
            </View>
          </View>
          <View
            style={{
              borderBottomWidth: 1,
              borderBottomColor: "black",
              borderBottomStyle: "solid",
              marginTop: 30,
              marginBottom: 10,
            }}
          />

          <Text style={styles.secText}>
            Date :{" "}
            {data?.others?.date
              ? data.others.date
              : new Date().toLocaleDateString()}
          </Text>
          <View
            style={{
              marginTop: 10,
            }}
          >
            <Text style={styles.secText}>The Manager,</Text>
            <Text style={{ fontSize: 11, fontFamily: "Inter-SemiBold" }}>
              {data?.bank_loan_name ? data?.bank_loan_name : ""}
            </Text>
          </View>

          <View
            style={{
              marginTop: 20,
            }}
          >
            <Text style={styles.secText}>Dear Sir / Madam,</Text>
          </View>

          <View
            style={{
              marginTop: 30,
            }}
          >
            <Text style={styles.thirdText}>
              RE : CREDITING OF HIRE PURCHASE (HP) DISBURSEMENT
            </Text>
          </View>

          <View
            style={{
              marginTop: 30,
            }}
          >
            <Text>
              <Text style={[styles.secText, { fontFamily: "Inter-SemiBold" }]}>
                Hirer{"              "}
              </Text>
              <Text style={[styles.secText, { fontFamily: "Inter-SemiBold" }]}>
                :
              </Text>
              <Text style={[styles.secText, { fontFamily: "Inter-SemiBold" }]}>
                {" "}
                {data?.personal_name ? data.personal_name : ""}
              </Text>
            </Text>
            <Text>
              <Text style={[styles.secText, { fontFamily: "Inter-SemiBold" }]}>
                Chassis No{"  "}
              </Text>
              <Text
                style={[
                  styles.secText,
                  { paddingLeft: 5, fontFamily: "Inter-SemiBold" },
                ]}
              >
                :
              </Text>
              <Text style={[styles.secText, { fontFamily: "Inter-SemiBold" }]}>
                {" "}
                {data?.vehicle_chasis_no ? data?.vehicle_chasis_no : ""}
              </Text>
            </Text>
            <Text style={[styles.secText, { fontFamily: "Inter-SemiBold" }]}>
              Engine No{"    "}:{" "}
              {data?.vehicle_engine_no ? data?.vehicle_engine_no : ""}
            </Text>
          </View>

          <View
            style={{
              marginTop: 30,
            }}
          >
            <Text style={styles.paragraph}>
              We refer to the HP financing provided by your company to our
              purchaser and would irrevocably instruct that all HP disbursement
              pertaining to the above financing be auto credited into our
              current{" "}
              <Text
                style={[styles.forthText, { fontFamily: "Inter-SemiBold" }]}
              >
                Account Number : {renderSpecialBankAcc()}
              </Text>{" "}
              in the name of{" "}
              <Text
                style={[styles.forthText, { fontFamily: "Inter-SemiBold" }]}
              >
                {" "}
                {data?.ap_detail?.name}
              </Text>{" "}
              maintained with{" "}
              <Text
                style={[
                  styles.forthText,
                  { marginTop: 2, fontFamily: "Inter-SemiBold" },
                ]}
              >
                {renderBank()}
              </Text>
              {/* <Text> at Taman Niaga Jaya,</Text>
              Bukit Mertajam Branch under advice to us. */}
              <Text> {renderSpecialAddress()} </Text>
            </Text>
          </View>

          <View
            style={{
              marginTop: 30,
              marginBottom: 30,
            }}
          >
            <Text style={styles.secText}>Thank you.</Text>
          </View>

          <Text style={styles.secText}>Yours faithfully,</Text>
          <Text style={styles.secText}>
            For{" "}
            <Text style={[styles.forthText, { fontFamily: "Inter-SemiBold" }]}>
              {data?.ap_detail?.name}
            </Text>
          </Text>

          <View
            style={{
              marginTop: 30,
            }}
          >
            <Text style={styles.secText}>
              --------------------------------------
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default FDSDisbusement;

import React, { useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Table,
} from "@react-pdf/renderer";

import InterBold from "../fonts/Inter.ttf";

// Font.register({
//   family: "Oswald",
//   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
// });

Font.register({
  family: "Inter-Bold",
  src: InterBold,
});

const styles = StyleSheet.create({
  container: {
    // fontFamily: "Inter",
    height: "auto",
  },
  borderBottom: {
    borderBottom: "1px solid black",
  },
  borderRight: {
    borderRight: "1px solid black",
  },
  table: {
    display: "flex",
    gap: "1px",
    flexDirection: "row",
  },
});

const VehicleFirstBalanceDetail = ({ data, setPrice }) => {
  const subBalance = (
    retailPrice,
    firstDeposit,
    secondDeposit,
    financeAmount
  ) => {
    let A = retailPrice ? Number(retailPrice) : 0;
    let B = firstDeposit ? Number(firstDeposit) : 0;
    let C = secondDeposit ? Number(secondDeposit) : 0;
    let D = financeAmount ? Number(financeAmount) : 0;

    const total = A - B - C;
    const formatTotal = Number(total.toFixed(2));

    if (total) {
      if (total < 0) {
        const number = Math.abs(formatTotal);
        return `(${number.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })})`;
      } else {
        return formatTotal.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    }
    return "0.00";
  };

  useEffect(() => {
    setPrice(
      subBalance(
        data?.vso?.retail_price_of_vahicle,
        data?.vso?.first_deposit_cheque_price,
        data?.vso?.second_deposit_cheque_price,
        data?.vso?.finance_amount
      )
    );
  }, []);

  // const renderBackgroundColor = () => {
  //   switch (data?.ap_id) {
  //     case 1:
  //       return "#D22B2B";
  //     case 2:
  //       return "#D22B2B";
  //     case 3:
  //       return "#FFBF00";
  //     case 4:
  //       return "#0D6856";
  //     default:
  //       return "#D22B2B";
  //   }
  // };

  const data_shown_name = data?.ap_detail?.shown_name;

  const renderBackgroundColor = () => {
    switch (data_shown_name) {
      case "DCF":
        return "#D22B2B";
      case "DCFP":
        return "#FFBF00";
      case "DCK":
        return "#538DD5";
      case "DCGS":
        return "#0D6856";
      case "DCP":
        return "#c3a372";
      case "DCHO":
        return "#00b8ad";
      default:
        return "#D22B2B";
    }
  };

  const formatCash = (cash) => {
    if (cash) {
      const formatTotal = Number(cash.toFixed(2));
      return formatTotal.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return "";
  };

  return (
    <View
      style={[styles.container, styles.borderBottom, { paddingBottom: "5px" }]}
    >
      <View style={[styles.table]}>
        <Text
          style={[
            {
              fontSize: "8px",
              paddingLeft: "2px",
              paddingTop: "2px",
              fontFamily: "Inter-Bold",
            },
          ]}
        >
          FOR BUYER'S / HIRER'S ACCOUNT:
        </Text>
      </View>
      <View style={[styles.table]}>
        <Text
          style={[
            {
              flex: 0.5,
              textAlign: "center",
              fontSize: "8px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        >
          (A)
        </Text>
        <Text
          style={[
            {
              flex: 7,
              fontSize: "8px",
              paddingRight: "2px",
              paddingTop: "2px",
              fontFamily: "Inter-Bold",
            },
          ]}
        >
          RETAIL PRICE OF VEHICLE
        </Text>
        <View style={[styles.table, { flex: 4.5 }]}>
          <Text
            style={[
              {
                flex: 1,
                textAlign: "center",
                fontSize: "8px",
                paddingTop: "2px",
                // fontFamily: "Inter",
              },
            ]}
          >
            RM
          </Text>
          <Text
            style={[
              {
                flex: 6,
                fontSize: "8px",
                paddingRight: "2px",
                paddingTop: "2px",
                fontWeight: "bold",
                fontFamily: "Inter-Bold",
                textAlign: "center",
              },
              styles.borderBottom,
            ]}
          >
            {formatCash(data?.vso?.retail_price_of_vahicle)}
          </Text>
        </View>
      </View>
      <View style={[styles.table]}>
        <Text
          style={[
            {
              flex: 0.5,
              textAlign: "center",
              fontSize: "8px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        ></Text>
        <Text
          style={[
            {
              flex: 11.5,
              fontSize: "7px",
              paddingRight: "2px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        >
          1) One year warranty Gear Box ,Engine & Steering rack
        </Text>
      </View>
      <View style={[styles.table]}>
        <Text
          style={[
            {
              flex: 0.5,
              textAlign: "center",
              fontSize: "8px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        ></Text>
        <Text
          style={[
            {
              flex: 11.5,
              fontSize: "7px",
              paddingRight: "2px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        >
          2) Touch up body if any
        </Text>
      </View>
      <View style={[styles.table]}>
        <Text
          style={[
            {
              flex: 0.5,
              textAlign: "center",
              fontSize: "8px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        ></Text>
        <Text
          style={[
            {
              flex: 11.5,
              fontSize: "7px",
              paddingRight: "2px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        >
          2) Touch up body if any
        </Text>
      </View>
      <View style={[styles.table]}>
        <Text
          style={[
            {
              flex: 0.5,
              textAlign: "center",
              fontSize: "7px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        ></Text>
        <Text
          style={[
            {
              flex: 11.5,
              fontSize: "8px",
              paddingRight: "2px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        >
          3) Engine oil & Gear box oil services
        </Text>
      </View>
      <View style={[styles.table]}>
        <Text
          style={[
            {
              flex: 0.5,
              textAlign: "center",
              fontSize: "8px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        ></Text>
        <Text
          style={[
            {
              flex: 11.5,
              fontSize: "7px",
              paddingRight: "2px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        >
          4) Alignment & Balancing
        </Text>
      </View>
      <View style={[styles.table, { paddingTop: "5px" }]}>
        <Text
          style={[
            {
              flex: 0.5,
              textAlign: "center",
              fontSize: "8px",
              // fontFamily: "Inter",
            },
          ]}
        >
          (B)
        </Text>
        <Text
          style={[
            {
              flex: 7,
              fontSize: "7px",
              paddingRight: "2px",
              // fontFamily: "Inter",
            },
          ]}
        >
          1ST DEPOSIT CHEQUE/CASH {data?.vso?.first_deposit_cheque_time}
        </Text>
        <View style={[styles.table, { flex: 4.5 }]}>
          <Text
            style={[
              {
                flex: 1,
                textAlign: "center",
                fontSize: "8px",
                // fontFamily: "Inter",
              },
            ]}
          >
            RM
          </Text>
          <Text
            style={[
              {
                flex: 6,
                fontSize: "8px",
                paddingRight: "2px",
                fontFamily: "Inter-Bold",
                textAlign: "center",
              },
              styles.borderBottom,
            ]}
          >
            {formatCash(data?.vso?.first_deposit_cheque_price)}
          </Text>
        </View>
      </View>
      <View style={[styles.table, { paddingTop: "5px" }]}>
        <Text
          style={[
            {
              flex: 0.5,
              textAlign: "center",
              fontSize: "8px",
              // fontFamily: "Inter",
            },
          ]}
        ></Text>
        <Text
          style={[
            {
              flex: 7,
              fontSize: "7px",
              paddingRight: "2px",
              // fontFamily: "Inter",
            },
          ]}
        >
          2ND DEPOSIT CHEQUE/CASH {data?.vso?.second_deposit_cheque_time}
        </Text>
        <View style={[styles.table, { flex: 4.5 }]}>
          <Text
            style={[
              {
                flex: 1,
                textAlign: "center",
                fontSize: "8px",
                // fontFamily: "Inter",
              },
            ]}
          >
            RM
          </Text>
          <Text
            style={[
              {
                flex: 6,
                fontSize: "8px",
                paddingRight: "2px",
                fontFamily: "Inter-Bold",
                textAlign: "center",
              },
              styles.borderBottom,
            ]}
          >
            {formatCash(data?.vso?.second_deposit_cheque_price)}
          </Text>
        </View>
      </View>
      <View style={[styles.table, { paddingTop: "5px" }]}>
        <Text
          style={[
            {
              flex: 0.5,
              textAlign: "center",
              fontSize: "8px",
              // fontFamily: "Inter",
            },
          ]}
        >
          (C)
        </Text>
        <Text
          style={[
            {
              flex: 7,
              fontSize: "7px",
              paddingRight: "2px",
              fontFamily: "Inter-Bold",
            },
          ]}
        >
          FINANCE AMOUNT
        </Text>
        <View style={[styles.table, { flex: 4.5 }]}>
          <Text
            style={[
              {
                flex: 1,
                textAlign: "center",
                fontSize: "8px",
                // fontFamily: "Inter",
              },
            ]}
          >
            RM
          </Text>
          <Text
            style={[
              {
                flex: 6,
                fontSize: "8px",
                paddingRight: "2px",
                fontFamily: "Inter-Bold",
                textAlign: "center",
              },
              styles.borderBottom,
            ]}
          >
            {formatCash(data?.vso?.finance_amount)}
          </Text>
        </View>
      </View>
      <View
        style={[
          styles.table,
          { paddingTop: "5px", paddingBottom: "2px", fontSize: "7px" },
        ]}
      >
        <View style={[styles.table, { flex: 4 }]}>
          <Text
            style={[
              {
                flex: 4,
                textAlign: "center",
                // fontFamily: "Inter",
              },
            ]}
          >
            *BANK:
          </Text>
          <Text
            style={[
              {
                flex: 2,
                paddingRight: "2px",
                fontFamily: "Inter-Bold",
              },
              styles.borderBottom,
            ]}
          >
            {data?.vso?.finance_bank}
          </Text>
          <Text
            style={[
              {
                flex: 3,
                paddingRight: "2px",
                // fontFamily: "Inter",
              },
            ]}
          ></Text>
        </View>
        <View style={[styles.table, { flex: 4 }]}>
          <Text
            style={[
              {
                flex: 4,
                textAlign: "center",
                // fontFamily: "Inter",
              },
            ]}
          >
            *HP Rate:
          </Text>
          <Text
            style={[
              {
                flex: 2,
                paddingRight: "2px",
                fontFamily: "Inter-Bold",
              },
              styles.borderBottom,
            ]}
          >
            {data?.vso?.finance_rate}
          </Text>
          <Text
            style={[
              {
                flex: 3,
                paddingRight: "2px",
                // fontFamily: "Inter",
              },
            ]}
          >
            %
          </Text>
        </View>
        <View style={[styles.table, { flex: 4 }]}>
          <Text
            style={[
              {
                flex: 4,
                textAlign: "center",
                fontSize: "8px",
                // fontFamily: "Inter",
              },
            ]}
          >
            *Period:
          </Text>
          <Text
            style={[
              {
                flex: 2,
                fontSize: "8px",
                paddingRight: "2px",
                textAlign: "center",
              },
              styles.borderBottom,
            ]}
          >
            {data?.vso?.finance_period_mount}
          </Text>
          <Text
            style={[
              {
                flex: 3,
                fontSize: "8px",
                paddingRight: "2px",
                // fontFamily: "Inter",
              },
            ]}
          >
            Months
          </Text>
        </View>
      </View>
      <View
        style={[
          styles.table,
          {
            backgroundColor: renderBackgroundColor(),
            color: "#FBFBFB",
            fontSize: "12px",
            fontFamily: "Inter-Bold",
          },
        ]}
      >
        <Text
          style={[
            {
              flex: 7,
              paddingRight: "15px",
              textAlign: "right",
            },
          ]}
        >
          1ST SUB BALANCE:
        </Text>
        <View style={[styles.table, { flex: 4.5 }]}>
          <Text
            style={[
              {
                flex: 1,
                textAlign: "center",
              },
            ]}
          >
            RM
          </Text>
          <Text
            style={[
              {
                flex: 6,
                // paddingRight: "15px",
                textAlign: "center",
              },
            ]}
          >
            {subBalance(
              data?.vso?.retail_price_of_vahicle,
              data?.vso?.first_deposit_cheque_price,
              data?.vso?.second_deposit_cheque_price,
              data?.vso?.finance_amount
            )}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default VehicleFirstBalanceDetail;

import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import ThreeColumnTable from "./components/three.column.table";
import TwoColumnTable from "./components/two.column.table";

// Font.register({
//   family: "Oswald",
//   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
// });

// Font.register({
//   family: "Calibri-Regular",
//   src: require("./fonts/Calibri-Regular.ttf"),
// });

// Font.register({
//   family: "Cambria",
//   src: require("./fonts/Cambria.ttf"),
// });

// Font.register({
//   family: "Times",
//   src: require("./fonts/times.ttf"),
// });

// Font.register({
//   family: "Times-Bold",
//   src: require("./fonts/times-bold.ttf"),
// });

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  mainView: {
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40,
  },
  bottomView: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    // fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 11.05,
    marginBottom: 3,
    textAlign: "left",
    // color: "red",
    // fontFamily: "Calibri-Regular",
  },
  header_small: {
    fontSize: 8,
    marginBottom: 3,
    textAlign: "left",
    color: "black",
    // fontFamily: "Calibri-Regular",
  },
  topText: {
    fontSize: 11.05,
    // fontFamily: "Calibri-Regular",
    paddingBottom: 3,
  },
  secText: {
    fontSize: 11,
    // fontFamily: "Cambria",
    padding: 8,
  },
  title: {
    fontSize: 24.95,
    padding: 10,
    // fontFamily: "Times-Bold",
    textAlign: "center",
    textDecoration: "underline",
  },

  threeColumnBorder: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
  },
  firstColumnText: {
    alignSelf: "center",
    alignItems: "flex-end",
    fontSize: 12,
    color: "black",
    // fontFamily: "Cambria",
  },
  secondColumnText: {
    alignItems: "flex-end",
    fontSize: 12,
    color: "black",
    // fontFamily: "Cambria",
  },
  lastcolumnText: {
    alignSelf: "center",
    alignItems: "flex-end",
    fontSize: 11,
    color: "black",
    // fontFamily: "Cambria",
  },
  twoColumnBorder: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
  },
  custColumnText: {
    alignItems: "flex-end",
    fontSize: 11,
    color: "black",
    // fontFamily: "Cambria",
  },
  custSecondColumnText: {
    alignItems: "flex-end",
    marginLeft: 8,
    fontSize: 11,
    color: "black",
    // fontFamily: "Cambria",
  },

  signHeader: {
    alignSelf: "center",
    marginTop: 30,
    fontSize: 11,
    color: "black",
    // fontFamily: "Cambria",
  },
  signSecondHeader: {
    alignSelf: "center",
    marginTop: 42,
    fontSize: 11,
    color: "black",
    // fontFamily: "Cambria",
  },
  signLine: {
    alignSelf: "center",
    marginTop: 20,
    fontSize: 11,
    color: "black",
    // fontFamily: "Cambria",
  },
  signText: {
    alignSelf: "center",
    fontSize: 11,
    color: "black",
    // fontFamily: "Cambria",
  },
  signSecondText: {
    marginLeft: 30,
    fontSize: 11,
    color: "black",
    // fontFamily: "Cambria",
  },
});

const bankCheckListData = [
  { first: "1", second: "Invoice", third: "" },
  { first: "2", second: "Disbursement Letter", third: "" },
  { first: "3", second: "B7 (Original with customer sign)", third: "" },
  { first: "4", second: "IC Customer / Company SSM", third: "-" },
  { first: "5", second: "Insurance", third: "" },
  { first: "6", second: "JPJ Registration Card and Roadtax", third: "" },
  { first: "7", second: "Customs Receipt", third: "" },
  { first: "8", second: "Customs K1", third: "" },
  { first: "9", second: "JK69", third: "" },
  { first: "10", second: "Export Certificate/UK V5", third: "" },
  { first: "11", second: "Preserved Record/HPI", third: "" },
  { first: "12", second: "Pekema's Verification Slip", third: "" },
  { first: "13", second: "Puspakom B2", third: "" },
  // { first: "14", second: "Delivery Order", third: "" },
];

const BankCheckList = ({ data }) => {
  const data_shown_name = data?.ap_detail?.shown_name;

  const renderLogo = () => {
    switch (data_shown_name) {
      case "DCF":
        return require("../../assets/images/FDS-logo.png");
      case "DCFP":
        return require("../../assets/images/FP-logo.png");
      case "DCK":
        return require("../../assets/images/KR-logo.png");
      case "DCGS":
        return require("../../assets/images/GS-logo.JPG");
      case "DCP":
        return require("../../assets/images/DCP-Logo.jpg");
      case "DCHO":
        return require("../../assets/images/DCHO-logo.png");
      case "DCH AUTOMOBILE":
        return require("../../assets/images/DCH-logo.png");
      default:
        return require("../../assets/images/FDS-logo.png");
    }
  };

  const renderAddressAP = () => {
    switch (data_shown_name) {
      case "DCF":
        return (
          <View style={{ marginTop: 5 }}>
            {data.ap_id == 1 && (
              <div>
                <Text style={styles.topText}>NO. 2 & 4, </Text>
                <Text style={styles.topText}>
                  JALAN PERDA UTAMA 7 BANDAR PERDA,
                </Text>
                <Text style={styles.topText}>
                  14000 BUKIT MERTAJAM PULAU PINANG. (HQ)
                </Text>
                <Text style={[styles.topText, { paddingBottom: 10 }]}>
                  Tel : <Text style={{}}>04-5308999</Text>
                </Text>
              </div>
            )}
            <Text style={styles.topText}>NO. 1, JALAN ASTAKA 4/KU2, </Text>
            <Text style={styles.topText}>BANDAR BUKIT RAJA,</Text>
            <Text style={styles.topText}>
              41050 KLANG, SELANGOR DARUL EHSAN.
            </Text>
            <Text style={styles.topText}>( SHOWROOM )</Text>
          </View>
        );
      case "DCFP":
        return (
          <View style={{ marginTop: 5 }}>
            <Text style={styles.topText}>No 3, Jalan Astana 1D,</Text>
            <Text style={styles.topText}>Bandar Bukit Raja,</Text>
            <Text style={styles.topText}>41050 Klang, Selangor.</Text>
          </View>
        );
      case "DCK":
        return (
          <View style={{ marginTop: 5 }}>
            <Text style={styles.topText}>22A, Jalan Astana 1B,</Text>
            <Text style={styles.topText}>Bandar Bukit Raja,</Text>
            <Text style={styles.topText}>41050 Klang Selangor.</Text>
          </View>
        );
      case "DCGS":
        return (
          <View style={{ marginTop: 5 }}>
            <Text style={styles.topText}>No.3, Jalan Kenanga 1/1,</Text>
            <Text style={styles.topText}>Salak Perdana,</Text>
            <Text style={styles.topText}>43900 Sepang ,Selangor.</Text>
          </View>
        );
      case "DCP":
        return (
          <View style={{ marginTop: 5 }}>
            <Text style={styles.topText}>No.6, Jalan Astana 1B,</Text>
            <Text style={styles.topText}>Bandar Bukit Raja,</Text>
            <Text style={styles.topText}>41050 Klang ,Selangor.</Text>
          </View>
        );
      case "DCHO":
        return (
          <View style={{ marginTop: 5 }}>
            <Text style={styles.topText}>27, Jalan Pemberita U1/49,</Text>
            <Text style={styles.topText}>Hicom-glenmarie Industrial Park,</Text>
            <Text style={styles.topText}>40150 Shah Alam, Selangor.</Text>
          </View>
        );
      case "DCH AUTOMOBILE":
        return (
          <View style={{ marginTop: 5 }}>
            <Text style={styles.topText}>No.7, Jalan Astana 1F/KU2,</Text>
            <Text style={styles.topText}>Bandar Bukit Raja,</Text>
            <Text style={styles.topText}>41050 Klang ,Selangor.</Text>
          </View>
        );
      default:
        return (
          <View style={{ marginTop: 15 }}>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              NO. 1, JALAN ASTAKA 4/KU2,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              BANDAR BUKIT RAJA,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              41050 KLANG,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              SELANGOR DARUL EHSAN.
            </Text>
          </View>
        );
    }
  };

  const renderFinanceName = () => {
    switch (data_shown_name) {
      // case "DCF":
      //   return "Finance : Maiza";
      // case "DCFP":
      //   return "Finance : ZIHAN";
      // case "DCK":
      //   return "Finance : FIQA";
      // case "DCGS":
      //   return 'Finance : FIQA';
      default:
        // return "Finance : ";
        return "";
    }
  };

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.mainView}>
          <View style={styles.container}>
            <View style={{ flex: 4 }}>
              <Image
                style={{ width: "70%", alignItems: "flex-start" }}
                src={renderLogo()}
              />
            </View>
            <View style={{ flex: 8 }}>
              <Text style={styles.header} fixed>
                {data?.ap_detail?.name}{" "}
                <Text style={styles.header_small}>
                  ({data?.ap_detail?.register_no})
                </Text>
              </Text>
              {/* {data.ap_id == 1 && (
                <div>
                  <Text style={styles.topText}>
                    No. 2 & 4, Jalan Perda Utama 7, Bandar Perda,
                  </Text>
                  <Text style={styles.topText}>
                    14000 Bukit Mertajam, Pulau Pinang. ( HQ )
                  </Text>
                  <Text style={styles.topText}>
                    Tel : 04-5308999 (Hunting Line) 04-5383600, 04-5388400
                  </Text>
                  <Text style={styles.topText}>Fax: 04-5302442</Text>
                </div>
              )}
              <Text style={styles.topText}>
                {data?.ap_detail?.address}
                {data.ap_id == 1 && (
                  <Text style={styles.topText}> ( SHOWROOM )</Text>
                )}
              </Text> */}
              {renderAddressAP()}
              <Text style={styles.topText}>
                Tel : {data?.ap_detail?.contact}
              </Text>
            </View>
          </View>
          <View
            style={{
              borderBottomWidth: 1,
              borderBottomColor: "black",
              borderBottomStyle: "solid",
              marginTop: 8,
            }}
          />
          <Text style={styles.secText}>
            Date :{" "}
            <Text style={{ textDecoration: "underline" }}>
              {data?.others?.date
                ? data.others.date
                : new Date().toLocaleDateString()}
            </Text>
          </Text>
          <Text style={styles.title}>Bank Checklist</Text>

          <View style={styles.bottomView}>
            <ThreeColumnTable
              data={data}
              column1="No"
              column2="Documents"
              column3="Remark"
              type="bankListHeader"
            />
            {bankCheckListData &&
              bankCheckListData.map((item, index) => (
                <div key={index}>
                  <ThreeColumnTable
                    column1={item.first}
                    column2={item.second}
                    column3={item.third}
                    type="bankList"
                  />
                </div>
              ))}
            <View style={styles.threeColumnBorder}>
              <View
                style={{ flex: 2, borderRight: "1px solid black", padding: 1 }}
              >
                <Text style={styles.firstColumnText}>14</Text>
              </View>
              <View
                style={{ flex: 8, padding: 1, borderRight: "1px solid black" }}
              >
                <Text style={styles.secondColumnText}>Delivery Order</Text>
              </View>
              <View style={{ flex: 2, padding: 1 }}>
                <Text style={styles.lastcolumnText}></Text>
              </View>
            </View>

            <div style={{ marginTop: 20 }}>
              <TwoColumnTable
                column1="Customer Name"
                column2={data?.personal_name}
                type="customerList"
              />
              <TwoColumnTable
                column1="Make"
                column2={data?.vehicle_make}
                type="customerList"
              />
              <TwoColumnTable
                column1="Car Model"
                column2={data?.vehicle_model}
                type="customerList"
              />
              <TwoColumnTable
                column1="Vehicle Chassis No."
                column2={data?.vehicle_chasis_no}
                type="customerList"
              />
              <View style={styles.twoColumnBorder}>
                <View
                  style={{
                    flex: 5,
                    borderRight: "1px solid black",
                    padding: 1,
                  }}
                >
                  <Text style={styles.custColumnText}>Vehicle Engine No.</Text>
                </View>
                <View style={{ flex: 7, padding: 1 }}>
                  <Text style={styles.custSecondColumnText}>
                    {data?.vehicle_engine_no}
                  </Text>
                </View>
              </View>
            </div>
          </View>
          <View style={styles.container}>
            <View style={{ flex: 6, padding: 1 }}>
              <Text style={styles.signHeader}>Prepared by: </Text>
              <Text style={styles.signLine}>
                ___________________________________
              </Text>
              <Text style={styles.signText}>{renderFinanceName()} </Text>
            </View>
            <View style={{ flex: 6, padding: 1 }}>
              <Text style={styles.signSecondHeader}>Received by:</Text>
              <Text style={styles.signLine}>
                ________________________________
              </Text>
              <Text style={styles.signSecondText}>Name: </Text>
              <Text style={styles.signSecondText}>IC No: </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default BankCheckList;
